import { INSTRUCTION } from './const';

const Instruction = () => {
  return (
    <pre className="instruction-container">
      { INSTRUCTION }
    </pre>
  );
}

export default Instruction;