import React, { useEffect, useCallback, useMemo } from 'react';
import API from '../../../services/api';
import styled from 'styled-components'

import { useTable } from "react-table";

const Styles = styled.div`
  padding: 1rem;

  table {
    margin: 0 auto;
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      background: #ccc;
    }
    
    th,
    td {
      margin: 0;
      padding: 3px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      text-align: center;

      :nth-child(4) {
        min-width: 40vw;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const Submission = ({ checked, setChecked, data, setData }) => {
  const getSubmission = useCallback(async(pid) => {
    setData(await API.call('GET', `/submission?pid=${pid}`, {}));
  }, [setData])

  useEffect(() => {

    getSubmission(checked + 1)
    const interval = setInterval(
      () => getSubmission(checked + 1)
    , 3000);
    
    return () => {
      clearInterval(interval);
    }
  }, [checked, getSubmission])

  const setup = (idx) => {
    setChecked(idx);
    getSubmission(idx + 1);
  }

  return (
    <div className="submission-container">
      <div className="submission-controller">
        程式碼執行結果:
        {
          Array(4).fill(0).map( (_, idx) =>
            <span key={idx}> 
              <input
                type="checkbox"
                checked={checked === idx}
                onChange={ () => setup(idx) }
              />
              <span>{`第${idx + 1}題 `}</span>
            </span>
          )
        }
      </div>
      <Styles>
      <Table
        columns={useMemo(() => [
          {
            Header: "序號",
            accessor: "id",
            className: "red"
          },
          {
            Header: "題號",
            accessor: "pid"
          },
          {
            Header: "語言",
            accessor: "language"
          },
          {
            Header: "執行",
            accessor: "title"
          },
          {
            Header: "執行結果",
            accessor: "result"
          },
          {
            Header: "時間",
            accessor: "time"
          }
        ], [])}
        data={useMemo(() => data, [data])}
      />
      </Styles>
    </div>
  );
}

export default Submission;