export const LANGUAGE_ID = {
  'c': 50, 
  'cpp': 54,
  'java': 62,
  'py2': 70,
  'py3': 71
};

export const EXTENSION = {
  'c': '.c',
  'cpp': '.cpp',
  'java': '.java',
  'py2': '.py',
  'py3': '.py'
};